import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { doCreate } from "../../../api";
import {
  ERRORMESSAGE,
  errorToastOptions,
  successToastOptions,
} from "../../../constants";
import { formatErrorMessage } from "../../../helpers";
import Textarea from "../../global/Textarea";
import useRequisition from "../../../hooks/useRequisition";

interface IViewRequisitionProps {
  onCancel: () => void;
  id: number;
  refresh: () => void;
}

const ViewRequisition = ({ onCancel, id, refresh }: IViewRequisitionProps) => {
  const [comment, setComment] = useState({ error: false, data: "" });
  const [loading, setLoading] = useState(false);
  const { getRequisition, requisition, getPurchaseOrder, purchaseOrder } =
    useRequisition();
  useEffect(() => getRequisition(id), []);
  useEffect(() => getPurchaseOrder(), []);
  console.log(requisition);
  const handleChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target as HTMLInputElement;
    if (value !== "") {
      console.log(value);
      setComment({ error: false, data: value });
    } else {
      setComment({ error: true, data: "" });
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (comment.data.length > 6) {
      const data = {
        comment: comment.data,
        state_id: 3,
        requisition_id: id,
      };

      setLoading(true);

      doCreate("process_purchase_requisitions", data)
        .then((response) => {
          if (response.data.success === true) {
            toast.success(
              "Your requisition successfully accepted",
              successToastOptions
            );
            refresh();

            setTimeout(() => {
              onCancel();
            }, 1000);
          } else {
            toast.error(
              formatErrorMessage(response.data.data, response.data.message),
              errorToastOptions
            );
          }
        })
        .catch(() => {
          toast.error(ERRORMESSAGE, errorToastOptions);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setComment({ error: true, data: comment.data });
      return;
    }
  };

  return (
    <form>
      <div className="form-row">
        <div className="col-sm-12"></div>
      </div>
      <div className="container">
      <h2>Requisition Details</h2>
        <div className="row">
          <div className="col-sm-4">
            <p>ID:</p>
          </div>
          <div className="col-sm-8">
            <p>{requisition?.id}</p>
          </div>
          <div className="col-sm-4">
            <p>Type:</p>
          </div>
          <div className="col-sm-8">
            <p>{requisition?.type}</p>
          </div>
          <div className="col-sm-4">
            <p>Requisition Date:</p>
          </div>
          <div className="col-sm-8">
            <p>{requisition?.requisition_date}</p>
          </div>
          <div className="col-sm-4">
            <p>Reference No:</p>
          </div>
          <div className="col-sm-8">
            <p>{requisition?.reference_no}</p>
          </div>
          <div className="col-sm-4">
            <p>Status:</p>
          </div>
          <div className="col-sm-8">
            <p>{requisition.status}</p>
          </div>
          <div className="col-sm-4">
            <p>Comment</p>
          </div>
          <div className="col-sm-8">
            <p>{requisition.requisition_comment}</p>
          </div>
          
        </div>
        <div className="row">
          <div className="col">
            <h5>Items</h5>
            <table className="table table-bordered striped bordered hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Quantity</th>
                  <th>Rate</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {requisition?.items?.map((item: any, index: number) => (
                  <tr key={index}>
                    <td>{item?.id}</td>
                    <td>{item?.item?.name}</td>
                    <td>{item?.quantity}</td>
                    <td>{item?.rate}</td>
                    <td>
                      {Number(item?.quantity * item?.rate).toLocaleString()}
                    </td>{" "}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button
          type="reset"
          className="btn btn-outline-danger mr-3"
          onClick={onCancel}
        >
          Close
        </button>
      </div>
    </form>
  );
};

export default ViewRequisition;
