import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { stockGet } from '../api/stocks';
import { RootState } from '../redux/app/store';
import { setSelectedStock, setStockReport, setStocks } from '../redux/reducer/stocks';

const useStocks = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const {
    stocks, selectedStock, stockReport
  } = useSelector(
    (state: RootState) => state.stocks,
  );

  useEffect(() => {

    if (stocks.length > 0) {
      setLoading(false);
      return;
    }

    stockGet('stock').then((response) => {
      if (response.status === 200) {
        dispatch(setStocks(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = () => {
    setLoading(true);
    stockGet('stock').then((response) => {
      if (response.status === 200) {
        dispatch(setStocks(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getStock = (id: number) => {
    setLoading(true);
    stockGet('stock/' + id).then((response) => {
      if (response.status === 200) {
        dispatch(setSelectedStock(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getStockReport = (start_date?: any,end_date?: any) => {
    setLoading(true);
    const URL = "items_stock_flow?start_date=" + start_date + "&?end_date=" + end_date;
    stockGet(URL).then((response) => {
      if (response.status === 200) {
        dispatch(setStockReport(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  return { stocks, loading, refresh, getStock, selectedStock, getStockReport, stockReport }
}

export default useStocks